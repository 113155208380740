import React, { ReactElement, useContext, useEffect, createContext, useState } from "react";
import styles from "./index.module.scss";
import Loader from "../../../components/Loader";
import { NavigationContext } from "../../../template";
import { useNavigate } from "react-router";
import Navigation from "../../../components/Navigation";
import Button from "../../../components/Button";
import SlideScreen from "../../../components/SlideScreen";
import Notification from "../../../components/Notification";
import VacationNew from "./components/VacationNew/index";
// import VacationItem from "../components/VacationItem";
import ChangeItem from "./components/Change";
import Types from "./components/Typec";
import word from "../../../shared/helpers/word";
import { InputTextArea } from "../../../components/Inputs";
export const VacationNext = createContext<{
    periodFinish(): any;
    periodFinishEndDate(): any;
    create: any;
    deleteArrEl: any;
    arr: any;
}>({
    periodFinish: () => null,
    periodFinishEndDate: () => null,
    create: () => null,
    deleteArrEl: () => null,
    arr: {},
});
const VacationNextYear = (props): ReactElement => {
    const {
        valueCause,
        valueStartDay, // начало отпуска
        valueFinishDay, // конец отпуска
        valueAmount, // кол-во дней
        editPutInfo, // измение данных отпуска для окна формирования заявления
        postVacationExternal, // внешний POST запрос
        errorExternal, // внешние ошибки
        clearErrorExternal, // очистка внешних ошибок
    } = props;
    const [load, setLoad] = useState(true);
    const [loader, setLoader] = useState(true);
    const context = useContext(NavigationContext);
    const [inputCount, setInputCount] = useState("");
    const navigate = useNavigate();
    const [Vacations, setVacations] = useState(false);
    const [arr, setArr] = useState<Array<any>>([]);
    const [screens, setScreen] = useState(0);
    const [userComment, setComment] = useState({
        comment: "",
    });
    const [error, setError] = useState({
        text: "",
        num: "",
    });
    const [userData2, setData2] = useState({
        dataStart: "",
        type: "",
        kolvo: "",
        Name: "",
        ID: "",
        inputCount: "",
    });
    const [err, setErr] = useState("");
    const [jsonData, setJsonData] = useState<any>({});
    const [jsonData2, setJsonData2] = useState<any>({});
    const [userVacation, setUserVacations] = useState({
        valueCause: valueCause ? valueCause : "По семейным обстоятельствам",
        oldStartDay: valueStartDay,
        valueStartDay: valueStartDay,
        valueFinishDay: valueFinishDay,
        valueAmount: valueAmount,
    });
    const prev = () => {
        if (screens === 0) {
            navigate("/");
        } else {
            setScreen(screens - 1);
        }
    };
    const [showChange, setShowChange] = useState({
        show: false, // показ окна редактирования
        showCancel: false, // показ окна отмены отпуска
        modified: false, // изменялся ли отпуск
    });
    const [userData, setData] = useState({
        StartDate: "",
        // monthEnd: "",
        type: "",
        // yearStart: "",
        // yearEnd: "",
        NumberDays: 0,
        adress: false,
        ID: "",
    });
    const show = (element) => {
        setShowChange({
            ...showChange,
            ...element,
        });
    };
    const clearError = (key: string) => {
        setError({
            ...error,
            [key]: "",
        });
    };
    const editVacation = (element) => {
        setUserVacations({
            ...userVacation,
            ...element,
        });
    };
    useEffect(() => {
        if (loader) {
            fetch("/dev/vacation_schedule_new_new", {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
            })
                .then((res) => res.json())
                .then((json) => {
                    if (json.res == true) {
                        setLoader(false);
                        setLoad(false);
                        setJsonData2(json);
                        setJsonData(json.available_vacations);
                    } else {
                        setLoad(false);
                        setLoader(false);
                    }
                })
                .catch((e) => {
                    console.error(e);
                });
        }
    }, [load]);
    const goToMain = () => {
        navigate("/");
        context.goToMain();
    };
    const next = () => {
        periodFinishApprove();
    };
    const templateYear = Number(`${new Date().getFullYear()}`);
    const templateMonth = Number(`${new Date().getMonth() + 1}`);
    const templateDay = Number(`${new Date().getDate()}`);
    const periodFinishApprove = () => {
        setLoad(true);
        setErr("");
        fetch("/dev/vacation_schedule_new_new", {
            method: "POST",
            body: JSON.stringify({
                Comment: "",
                Periods: arr,
            }),
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        })
            .then((res) => res.json())
            .then((json) => {
                if (json.res) {
                    setLoad(false);
                    setScreen(screens + 1);
                } else {
                    // Заявление не сформировано
                    setLoad(false);
                    setErr(json.Error);
                }
            })
            .catch((e) => {
                console.error(e);
            });
        ``;
    };
    const periodFinishApprove2 = () => {
        setLoad(true);
        setErr("");
        fetch("/dev/vacation_schedule_new_new", {
            method: "PUT",
            body: JSON.stringify({
                Comment: userComment.comment,
            }),
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        })
            .then((res) => res.json())
            .then((json) => {
                if (json.res) {
                    setLoad(false);
                    navigate("/");
                    context.goToMain();
                } else {
                    // Заявление не сформировано
                    setLoad(false);
                    setErr(json.Error);
                }
            })
            .catch((e) => {
                console.error(e);
            });
        ``;
    };
    const periodFinish = () => {
        setLoad(true);
        setErr("");
        fetch("/dev/vacation_vac_check_new", {
            method: "POST",
            body: JSON.stringify({
                Comment: "",
                ID: userData.ID,
                StartDate: userData2.dataStart.replace(".2", ".202"),
                NumberDays: userData2.inputCount,
            }),
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        })
            .then((res) => res.json())
            .then((json) => {
                if (json.res) {
                    setLoad(false);
                    props.setShowChange(false);
                } else {
                    // Заявление не сформировано
                    setErr(json.Error);
                    setLoad(false);
                }
            })
            .catch((e) => {
                console.error(e);
            });
        ``;
    };

    const periodFinishEndDate = () => {
        setLoad(true);
        setErr("");
        fetch("/dev/vacation_cancel_new", {
            method: "PUT",
            body: JSON.stringify({
                Comment: "",
                ID: userData.ID,
                StartDate: userData2.dataStart.replace(".2", ".202"),
                NumberDays: userData2.inputCount,
            }),
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        })
            .then((res) => res.json())
            .then((json) => {
                if (json.res) {
                    setLoad(false);
                } else {
                    // Заявление не сформировано
                    setErr(json.Error);
                    setLoad(false);
                }
            })
            .catch((e) => {
                console.error(e);
            });
        ``;
    };
    const create = (el, objToDelete: any = {}) => {
        if (Object.keys(objToDelete).length) {
            setArr([...arr.filter((el) => el.NumberDays != objToDelete.kolvo1 || el.StartDate != objToDelete.Start1 || el.Name != objToDelete.Name1), el]);
        } else {
            setArr([...arr, el]);
        }
    };
    const deleteArrEl = (el) => {
        // arr.filter((el) => el.ID !== el.ID && el.NumberDays == el.NumberDays && el.StartDate == el.NumberDays);
        arr.filter((el) => {
            if (el.NumberDays == userData.NumberDays && el.ID == userData.ID && el.StartDate == userData.StartDate) el.NumberDays = "";
        });
    };
    const editData = (key, element) => {
        setData({
            ...userData,
            [key]: element,
        });
    };
    const editComment = (key, element) => {
        setComment((prevState) => ({
            ...prevState,
            [key]: element,
        }));
    };
    if (userData2.Name === "Отпуск по инвалидности") {
        userData2.ID = "af3d2a4e-e1d2-11e8-80bc-00155d545503";
    }
    if (userData2.Name === "Отпуск за вредность") {
        userData2.ID = "9cc33f5a-a154-11e9-80bf-00155d9b230c";
    }
    if (userData2.Name === "Северный") {
        userData2.ID = "95c39a82-01c2-11e8-80c6-00155d545500";
    }
    if (userData2.Name === "Основной") {
        userData2.ID = "4e9b9a52-5773-11e7-80d7-40f2e9a19d93";
    }
    // useEffect(() => {
    //     console.log("<<<<<<<", arr, ">>>>>>>>>>>>");
    // }, [arr]);
    // console.log(arr);
    const result = arr.map((el) => {
        return (
            <>
                {Vacations ? (
                    <>
                        <ChangeItem
                            typeVacation={el.Name}
                            valueCause={el.Name}
                            typeItem={"graph"}
                            valueStartDay={el.StartDate}
                            valueFinishDay={el.KonetsDate}
                            valueAmount={el.NumberDays}
                            // idVacation={el.Name}
                            typeText={el.Name}
                            valueCauseid={el.ID}
                            setArr={setArr}
                        />
                        {/* <div className={styles.typeVacationNew}>{el.Name}</div> */}
                    </>
                ) : null}
            </>
        );
    });
    const resultfinal = arr.map((el) => {
        return (
            <>
                {Vacations ? (
                    <>
                        <p className={styles.infoCheck_zagolovok}>{el.Name} отпуск</p>
                        <p className={styles.infoCheck_text}>
                            с {el.StartDate} по {el.KonetsDate}, {el.NumberDays} {word(Number(el.NumberDays), ["день", "дня", "дней"])}
                        </p>
                    </>
                ) : null}
            </>
        );
    });
    return (
        <VacationNext.Provider
            value={{
                periodFinish,
                periodFinishEndDate,
                create,
                deleteArrEl,
                arr,
            }}
        >
            <>
                {load ? (
                    <>
                        <Loader></Loader>
                        <Navigation></Navigation>
                    </>
                ) : (
                    <>
                        <Navigation prev={prev} goToMain={goToMain}></Navigation>
                        <div className={styles.dis}>
                            {screens === 0 ? (
                                <>
                                    <p className={styles.dis_title}>График отпусков на 2025</p>
                                    <p className={styles.second_title}>Добавьте желаемые даты отпусков для согласования с руководителем</p>
                                    <div className={styles.error}>{err}</div>
                                    <div className={styles.types}>
                                        <Notification type="info" icon="info">
                                            Запланируйте отпуска
                                        </Notification>
                                        <Types
                                            load={load}
                                            periodFinishEndDate={periodFinishEndDate}
                                            periodFinish={periodFinish}
                                            setData={setData}
                                            setLoad={setLoad}
                                            next={next}
                                            data={jsonData}
                                        />
                                    </div>
                                    <Button type="add_vacation" icon="plus-interface" onClick={() => show({ show: true })}>
                                        Добавить отпуск
                                    </Button>
                                    {result}
                                    {showChange.show ? (
                                        <div className={styles.button}>
                                            <SlideScreen titleText="Добавить отпуск" altitudeLevel="full" show={show}>
                                                <VacationNew
                                                    error={errorExternal ? errorExternal : error}
                                                    clearError={clearErrorExternal ? clearErrorExternal : clearError}
                                                    editVacation={postVacationExternal && editPutInfo ? editPutInfo : editVacation}
                                                    postVacation={postVacationExternal ? postVacationExternal : editPutInfo}
                                                    reasons={jsonData2}
                                                    setShowChange={setShowChange}
                                                    editData={editData}
                                                    inputCount={inputCount}
                                                    setInputCount={setInputCount}
                                                    create={create}
                                                    setVacations={setVacations}
                                                />
                                            </SlideScreen>
                                        </div>
                                    ) : null}
                                    <SlideScreen altitudeLevel="service">
                                        <Button type="save_not_margin" onClick={() => next()}>
                                            Сформировать заявление
                                        </Button>
                                    </SlideScreen>
                                </>
                            ) : null}
                            {screens === 1 ? (
                                <>
                                    <p className={styles.dis_title}>
                                        Заявление на график отпусков от {templateDay}.{templateMonth}.{templateYear}{" "}
                                    </p>
                                    <Notification type="info" icon="info">
                                        Проверьте введённые данные и отправьте график на согласование.
                                    </Notification>
                                    <div className={styles.error}>{err}</div>
                                    <div className={styles.infoCheck}>
                                        <p className={styles.infoCheck_zagolovok}>ФИО сотрудника</p>
                                        <p className={styles.infoCheck_text}>{context.userdata.name}</p>
                                        <p className={styles.infoCheck_zagolovok}>Руководитель</p>
                                        <p className={styles.infoCheck_text}>{context.userdata.boss_name}</p>
                                    </div>
                                    {resultfinal}
                                    <InputTextArea
                                        // onBlur={() => onBlur()}
                                        // onFocus={() => onFocus()}
                                        type="area"
                                        placeholder="Напишите комментарий"
                                        editData={editComment}
                                        name="comment"
                                        value={userComment.comment}
                                    />
                                    <SlideScreen altitudeLevel="service">
                                        <Button type="save_not_margin" onClick={() => periodFinishApprove2()}>
                                            Сформировать заявление
                                        </Button>
                                    </SlideScreen>
                                </>
                            ) : null}
                        </div>
                    </>
                )}
            </>
        </VacationNext.Provider>
    );
};

export default VacationNextYear;
