import React from "react";

const menu: any = [
    {
        menuTitle: "",
        description: "",
        id: "1",
        title: "Оформить заявление",
        icon: "Menu1",
        children: [
            {
                menuTitle: "Оформление заявления",
                description: "Выберите тип заявления",
                title: "Отпуск",
                id: "11",
                checkId: "edo_aval",
                icon: "Menu2Otpysk-palma",
                children: [
                    {
                        menuTitle: "Оформление заявления",
                        description: "Выберите тип отпуска",
                        title: "Оплачиваемый отпуск",
                        id: "111",
                        checkId: "edo_aval",
                        icon: "Menu1",
                        link: "/vacation/",
                    },
                    {
                        title: "Отпуск за свой счёт",
                        icon: "Menu2ZASvoyChet",
                        id: "112",
                        checkId: "edo_aval",
                        link: "/vacationExpense/",
                    },
                    {
                        title: "Дополнительный отпуск для IT",
                        icon: "Menu2ITDopOtp",
                        id: "113",
                        checkId: "it_vac_aval",
                        link: "/vacationIT/",
                    },
                    {
                        title: "Декретный отпуск",
                        icon: "MaternityLeave",
                        id: "114",
                        checkId: "edo_aval",
                        link: "/vacationChildCare/",
                    },
                    {
                        title: "Перенос отпуска",
                        icon: "Menu2Perenos",
                        id: "115",
                        checkId: "edo_aval",
                        link: "/vacationTransposition/",
                    },
                    {
                        title: "График отпусков на 2025",
                        id: "116",
                        checkId: "edo_aval",
                        icon: "MenucalendarClear",
                        dynamicDateNext: true,
                        link: "/vacationNextYear/",
                    },
                    // {
                    //     title: "Мой график отпусков",
                    //     icon: "MenucalendarClear",
                    //     dynamicDate: true,
                    //     id: "115",
                    //     link: "/vacSchedule/",
                    // },
                ],
            },
            {
                title: "Отгулы",
                id: "12",
                icon: "time_off_icon",
                link: "/timeOff/",
                checkId: "dayoff_aval",
            },
            {
                title: "Реквизиты для перечисления ЗП",
                id: "13",
                icon: "Menu2RecvizitiZP",
                link: "/PODetails/",
                checkId: "edo_aval1",
            },
            {
                title: "Авансовый отчет",
                id: "14",
                icon: "ruble_bold",
                link: "/advanceReport/",
                checkId: "advrep_aval",
            },
            {
                title: "Изменение персональных данных",
                id: "15",
                icon: "PersonalData",
                link: "/changePersonalDate/",
                checkId: "edo_aval1",
            },
            {
                title: "Вычет на детей",
                id: "16",
                icon: "deductionForChildren",
                link: "/deductionForChildren",
                checkId: "vychet_aval",
            },
            {
                title: "Увольнение",
                id: "17",
                icon: "Dismissal",
                link: "/dismissalStaf/",
                checkId: "edo_aval",
            },
        ],
    },
    {
        title: "Запросить документ",
        icon: "Menu2",
        id: "2",
        children: [
            {
                menuTitle: "Запрос документа",
                description: "Выберите тип документа",
                title: "Расчетный лист",
                id: "21",
                icon: "paySlip_Menu",
                link: "/paySlip/",
                checkId: "edo_aval2",
            },
            {
                title: "Справки",
                id: "22",
                icon: "listok",
                link: "/inquiries/",
                checkId: "edo_aval2",
            },
            {
                title: "Расходы на доп. услуги",
                id: "23",
                icon: "money_pin_location",
                link: "/expensesForAddServices/",
            },
            {
                title: "Мой график отпусков",
                id: "24",
                icon: "MenucalendarClear",
                dynamicDate: true,
                link: "/vacSchedule/",
            },
            {
                title: "Остатки отпусков",
                id: "25",
                icon: "Menucalendar22",
                link: "/vacBalances/",
            },
            {
                title: "Закрепленные ТМЦ",
                id: "26",
                icon: "TMTS",
                link: "/assignInventory/",
            },
            {
                title: "Отпуска подчиненных",
                id: "27",
                icon: "Menupeopleteam",
                link: "/vacOfSubord/",
                checkId: "is_boss",
            },
            {
                title: "ТМЦ подчиненных",
                id: "28",
                icon: "TMTS_subordinates",
                link: "/assignInventoryOfSub/",
                checkId: "is_boss",
            },
            {
                title: "Отгулы подчиненных",
                id: "29",
                icon: "time_off_icon",
                link: "/timeOffOfSub/",
                checkId: "is_boss",
                rescheduling: true,
            },
        ],
    },
    {
        title: "Мои заявления",
        icon: "Menu3",
        number: "apps_not_comp",
        //checkId: "apps_not_comp",
        id: "3",
        link: "/requestsList/",
    },
    {
        title: "Согласования",
        icon: "Menu4",
        number: "apps_not_appr",
        id: "4",
        checkId: "appr_vis",
        link: "/approve/",
    },
    {
        title: "Корпоративные соц. сети",
        rescheduling: true,
        id: "5",
        icon: "Menu5",
        children: [
            {
                menuTitle: "Корпоративные соц. сети",
                description: "Подпишись на корпоративные каналы и будь в курсе всех новостей о компании SOKOLOV.",
                id: "51",
                icon: "la_telegram-plane",
                checkId: "is_retail",
                title: "Лидеры розницы SOKOLOV",
                type: "TgCh",
                spam: "Полезная информация, конкурсы и вдохновение!",
                ID_channel: "-1001745314862",
            },
            {
                title: "SOKOLOV Производство",
                id: "52",
                icon: "la_telegram-plane",
                type: "TgCh",
                spam: "Важная информация и новости компании! Обмен опытом и идеями с коллегами, интересные розыгрыши.",
                checkId: "is_proizv",
                ID_channel: "-1002157779265",
            },
            {
                title: "SOKOLOV Офис",
                id: "53",
                icon: "la_telegram-plane",
                type: "TgCh",
                spam: "Важная информация и новости компании! Обмен опытом и идеями с коллегами, интересные розыгрыши.",
                checkId: "is_office",
                ID_channel: "-1002172998794",
            },
            {
                title: "RealPeople",
                id: "54",
                icon: "la_telegram-plane",
                type: "TgCh",
                spam: "Современные технологии, IT-новости, полезные статьи и крутые конкурсы!",
                checkId: "is_realp",
                ID_channel: "-1001279045860",
            },
            {
                title: "Дивизион на миллион",
                id: "55",
                icon: "la_telegram-plane",
                type: "TgCh",
                spam: "Оптовый канал о планах и бонусах за их выполнение.",
                checkId: "is_divmil",
                ID_channel: "-1001682056666",
            },
            {
                title: "1 линия поддержки IT-Retail",
                id: "56",
                icon: "la_telegram-plane",
                type: "TgCh",
                spam: "Помощь в решении проблем с 1С у розничных магазинов.",
                checkId: "is_techp1",
                ID_channel: "-1001928348498",
            },
            {
                title: "СБОРНАЯ МСК",
                id: "57",
                icon: "la_telegram-plane",
                type: "TgCh",
                spam: "Информационная группа для оперативного обмена информацией между командами магазинов.",
                checkId: "is_msc1",
                ID_channel: "-1001919086024",
            },
            {
                title: "ДИРЕКЦИЯ МСК",
                id: "58",
                icon: "la_telegram-plane",
                type: "TgCh",
                spam: "Группа для директоров магазинов по решению организационных вопросов.",
                checkId: "is_msc2",
                ID_channel: "-1001829198205",
            },
            {
                title: "Север7",
                id: "59",
                icon: "la_telegram-plane",
                type: "TgCh",
                spam: "Информационная группа для оперативного обмена информацией между командами магазинов.",
                checkId: "is_msc3",
                ID_channel: "-1001735192018",
            },
            {
                title: "Мерч Север7",
                id: "60",
                icon: "la_telegram-plane",
                type: "TgCh",
                spam: "Группа с мерчандайзерами для оперативного информирования по изменению оформления магазина.",
                checkId: "is_msc4",
                ID_channel: "-1001666211396",
            },
            {
                title: "СБОРНАЯ УРАЛ",
                id: "61",
                icon: "la_telegram-plane",
                type: "TgCh",
                spam: "Информационная группа для оперативного обмена информацией между командами магазинов.",
                checkId: "is_ural",
                ID_channel: "-1002096834360",
            },
            {
                title: "Коммерция и Логистика SOKOLOV",
                id: "62",
                icon: "la_telegram-plane",
                type: "TgCh",
                spam: "Чат для обмена информацией по заказам, по выходу украшений с производства и упаковке.",
                checkId: "is_colo",
                ID_channel: "-1001635279128",
            },
            {
                title: "PRIORITY СИБИРЬ",
                id: "63",
                icon: "la_telegram-plane",
                type: "TgCh",
                spam: "Информационная группа для оперативного обмена информацией между командами магазинов.",
                checkId: "is_sbr",
                ID_channel: "-1001281764469",
            },
            {
                title: "SOKOLOV POWER",
                id: "64",
                icon: "la_telegram-plane",
                type: "TgCh",
                spam: "Полезная информация, конкурсы и вдохновение!",
                checkId: "is_pwr",
                ID_channel: "-1001668486319",
            },
            {
                title: "СБОРНАЯ СПБ",
                id: "65",
                icon: "la_telegram-plane",
                type: "TgCh",
                spam: "Информационная группа для команд магазинов региона СПб. Оперативные вопросы и вдохновение.",
                checkId: "is_spb",
                ID_channel: "-1002151586754",
            },
            {
                title: "VM SOKOLOV розница",
                id: "66",
                icon: "la_telegram-plane",
                type: "TgCh",
                spam: "Полезная информация по VM в магазинах SOKOLOV.",
                checkId: "is_vm",
                ID_channel: "-1002042099653",
            },
            {
                title: "Роза SOKOLOV 2024",
                id: "67",
                icon: "la_telegram-plane",
                type: "TgCh",
                spam: "Нажмите, чтобы перейти к каналу в Telegram",
                checkId: "rose_aval",
                ID_channel: "-1002094951218",
            },
            {
                title: "Мы - SOKOLOV",
                id: "68",
                icon: "vk",
                type: "TgCh",
                spam: "Закрытая корпоративная группа, в которой публикуют все самые интересные новости компании!",
                url: "https://vk.com/komanda_sokolov",
            },
        ],
    },
    {
        title: "Роза SOKOLOV 2024",
        icon: "rose",
        id: "6",
        link: "/Rose/",
        checkId: "rose_aval",
        // number: "sd_opened",
    },
    {
        title: "Настройка уведомлений",
        icon: "setting_ntf",
        id: "7",
        // link: "/Setting/",
        type: "setting_ntf",
        // checkId: "boss_or_it",
    },
    {
        title: "Клиентская поддержка",
        icon: "Menu6",
        id: "8",
        checkId: "supp_aval",
        number: "sd_opened",
        children: [
            {
                menuTitle: "Клиентская поддержка",
                title: "Написать обращение",
                id: "81",
                icon: "Menuplane",
                link: "/newAppeals/",
            },
            {
                title: "Мои обращения",
                id: "82",
                icon: "Menuclipboardoutline",
                link: "/myAppeals/",
                number: "sd_opened",
            },
        ],
    },
];

export default menu;
